export const indexMobileBreakpointOld = 851
export const indexMobileBreakpoint = 700

export enum GeneralState {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}

export enum RsvpStatus {
  GOING = 'GOING',
  CANT = 'CANT',
  MAYBE = 'MAYBE',
  JOINED = 'JOINED',
  REQUESTED = 'REQUESTED',
  REQUEST_DENIED = 'REQUEST_DENIED',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum Status {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export interface TicketOptionAndAmount {
  ticketOptionId: number
  quantity: number
  price?: number
  code?: string
}

export interface AnalyticsProperties {
  banditId?: number
  banditDisplayTagId?: number
  banditCuratedSectionId?: number
}

export interface EventFormResponsePostDto {
  questionId: number
  response: any // Assuming JsonNode is replaced by any type
}

export interface GuestRsvpInfo {
  name?: string
  emailAddress: string
  notificationToken?: string
  languageCode?: string
  distinctId?: string
  firstName?: string
  lastName?: string
  stripeCustomerId?: string
}

export interface RsvpDtoV2 {
  status: RsvpStatus
  joinOption?: TicketOptionAndAmount
  message?: string
  analyticsProperties?: AnalyticsProperties
  formResponses?: EventFormResponsePostDto[]
  guestInfo?: GuestRsvpInfo
}

export interface DateOptionsRsvpDto {
  dateOptions: RsvpDateOptionDto[]
  guestInfo?: GuestRsvpInfo
}

export interface RsvpDateOptionDto {
  id: number
  status: RsvpStatus
}

export interface WaitListDto {
  waitlist: boolean
  guestInfo?: GuestRsvpInfo
}

export interface HypeDto {
  hype: boolean
  guestInfo?: GuestRsvpInfo
}

export interface UserGuestMinimalDto {
  id: number
  status?: RsvpStatus
  state: State // Assuming State is defined somewhere else
  gaveDateOptions: boolean
  likedByYou?: boolean
  emailAddress?: string
  likes?: number
  questionsCompleted: boolean
  message?: string
  code?: string
  perk?: any // Assuming GuestPerkDto is defined somewhere else
  pendingTicketOrder?: TicketOrderDto
  ticketCount: number
  waitingList: boolean
  role?: string
  user?: any
  name?: string
  joinOption?: any
  hasAvatar?: boolean
  completedTicketOrders?: TicketOrderDto[]
  hype: boolean
}

export interface TicketOrderDto {
  id: number
  paymentStatus: PaymentStatus
  status: Status
  expiresAt: Date
  event?: any // Assuming EventBaseDto is defined somewhere else
  currentPaymentIntentId?: string
  currentCheckoutSessionId?: string
  externalUrl?: string
  joinOption?: any
  completedAt?: Date
}

export enum State {
  INVITED = 'INVITED',
  UNVERIFIED = 'UNVERIFIED',
}

export enum Social {
  whatsapp = 'WhatsApp',
  stories = 'Stories',
  snapchat = 'Snapchat',
  linkedin = 'LinkedIn',
  feed = 'Feed',
  image = 'Share image',
  link = 'Share link',
}

export enum ShareLocation {
  web_post_rsvp = 'web_post_rsvp',
  web_share_organiser = 'web_share_organiser',
}

export enum ShareMedium {
  generic = 'generic',
  copylink = 'copylink',
  image = 'image'
}