import React from 'react'
import { Box, BoxProps } from '@mui/material'

const Row = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box
      {...props}
      ref={ref}
      sx={[
        {
          display: 'flex',
          flexDirection: 'row',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  )
})

export default Row